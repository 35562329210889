"use strict";

function loadNavEditorials(url) {
  fetch(url).then(function (response) {
    if (response.status !== 200 || !response.ok) throw new Error("Unexpected response: status ".concat(response.status));
    return response.json();
  }).then(function (aside) {
    var options = "";
    if (!aside.items) throw new Error("Unexpected editorials format: missing items");
    aside.items.forEach(function (editorial) {
      var urlEditoria = "/noticias/".concat(editorial.slug);
      options += "<li><a href=\"".concat(urlEditoria, "\">").concat(editorial.name, "</a></li>");
    });
    $(".sub-menu-editorials").html(options);
    return aside;
  })["catch"](function (error) {
    console.error(error);
  });
}